
import React from 'react'
import sanitizeHtmlReact from 'sanitize-html-react'
import SVGInline from 'react-svg-inline'
import dropdownArrow from '../../shared/assets/DropdownArrow.svg'
import exclamationCircle from '../../shared/assets/exclamation-circle.svg'
import { togglePreventScroll, cleanNumber } from '../../shared/utility-functions'
import './header-nav.scss'
import './dynamic-nav.scss'

export default class DynamicNav extends React.Component {
  constructor (props) {
    super(props)
    this.siteWrapper = document.querySelector('.site-wrapper')
    this.state = {
      menuOpen: false,
      openMenu: -1,
      openDrop: [],
      scrollPosition: 0
    }
    this.offClick = this.offClick.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleContent = this.toggleContent.bind(this)
    this.renderMainItems = this.renderMainItems.bind(this)
    this.renderTopItems = this.renderTopItems.bind(this)
    this.selectedContent = this.selectedContent.bind(this)
    window.addEventListener('click', this.offClick, {passive: true})
  }

  selectedContent () {
    return this.props.mainNav[this.state.openMenu]
  }

  toggleMenu () {
    let oldScrollPosition = $(document).scrollTop()
    let newState = !this.state.menuOpen
    this.setState({ menuOpen: newState })
    setTimeout(() => {
      togglePreventScroll(newState)
    }, 100)
    setTimeout(() => {
      this.setState({ openMenu: -1 })
    }, 300)
    if (newState) {
      this.setState({ scrollPosition: oldScrollPosition })
      window.scroll(0, 0)
    } else {
      setTimeout(() => {
        window.scroll(0, this.state.scrollPosition)
      }, 200)
    }
  }

  offClick (e) {
    if ((this.state.openMenu !== -1 || this.state.openDrop) && window.screen.width > 767) {
      try {
        let notMenuButton = e.target.parentElement.className.indexOf('item with-arrow main-nav--mega-menu-actuator') === -1
        let notMenuOption = e.target.parentElement.className.indexOf('medium-expand') === -1
        if (notMenuButton && notMenuOption && (this.state.openMenu !== -1 || this.state.openDrop.length)) {
          this.setState({ openMenu: -1, openDrop: [] })
        }
      } catch (error) { }
    }
  }

  toggleDropdown (id) {
    if (window.screen.width > 768) {
      if (this.state.openDrop.indexOf(id) > -1) {
        this.setState({ openDrop: [] })
      } else {
        this.setState({ openDrop: [id], openMenu: -1 })
      }
    } else {
      if (this.state.openDrop.indexOf(id) > -1) {
        let newdrop = this.state.openDrop
        newdrop.splice(newdrop.indexOf(id), 1)
        this.setState({ openDrop: newdrop })
      } else {
        let array = this.state.openDrop
        array.push(id)
        this.setState({ openDrop: array })
      }
    }
  }

  toggleContent (id) {
    if (typeof id === 'object' || id === this.state.openMenu) {
      this.setState({ openMenu: -1 })
    } else {
      this.setState({ openMenu: id, openDrop: [] })
    }
  }

  renderMainItems () {
    return this.props.mainNav.map((item, index) => {
      return item.type === 'link'
        ? <li key={index} className='column small-12 medium-expand'><a className={`item ${Number(this.props.sectionID) !== 0 && this.props.sectionID === item.id && this.state.openMenu === -1 ? 'selected' : ''}`} href={item.content && item.content.url ? item.content.url : item.content} target={item.content && item.content.target ? item.content.target : '_self'}>{item.title}</a></li> : (
          item.type === 'dropdown'
            ? <li key={index} className='column small-12 medium-expand dropdown-nav-item' onMouseEnter={() => { if (window.innerWidth > 768) { this.toggleDropdown(index) } }}
              onMouseLeave={() => { if (window.innerWidth > 768) { this.toggleDropdown(index) } }}>
              <button onClick={() => { this.toggleDropdown(index) }} className={`item with-arrow main-nav--mega-menu-actuator ${this.state.openDrop.indexOf(index) > -1 ? 'active' : ''} ${Number(this.props.sectionID) !== 0 && this.props.sectionID === item.id && this.state.openDrop.indexOf(index) === -1 ? 'selected' : ''}`}>
                <span>{item.title}</span>
                <SVGInline svg={dropdownArrow} className='icon' />
              </button>
              <div className='main-nav--mega-menu-container dropdown-container text-left' style={{ height: this.state.openDrop.indexOf(index) > -1 ? 'auto' : 0 }}>
                <div className='align-right dropdown'>
                  <div className='column'>
                    <ul className='no-bullet'>
                      {
                        item.content && item.content.map(link => {
                          return <li><a alt={link.title} target={link.target || '_blank'} href={link.link}>{link.text}</a></li>
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            : <li key={index} className='column small-12 medium-expand' onMouseEnter={() => { if (window.innerWidth > 768) { this.toggleContent(index) } }}
              onMouseLeave={() => { if (window.innerWidth > 768) { this.toggleContent(index) } }}>
              <button onClick={() => { this.toggleContent(index) }} className={`item with-arrow main-nav--mega-menu-actuator ${this.state.openMenu === index ? 'active' : ''} ${Number(this.props.sectionID) !== 0 && this.props.sectionID === item.id && this.state.openMenu === -1 ? 'selected' : ''}`}>
                <span>{item.title}</span>
                <SVGInline svg={dropdownArrow} className='icon' />
              </button>
              <div className='main-nav--mega-menu-container text-left' style={{ height: this.state.openMenu === index ? 'auto' : 0 }}>
                <div className='row row-full-width align-right dynamic-menu main-nav--mega-menu-content'>
                  <div className='column small-12 medium-11'>
                    <div className='row row-full-width align-spaced' dangerouslySetInnerHTML={{ __html: sanitizeHtmlReact(item.content) }} />
                  </div>
                </div>
              </div>
            </li>)
    })
  }

  renderMenu () {
    return this.selectedContent() && <div className='mega-menu-section'>
      <div className='mega-header'>{this.selectedContent().title}</div>
      {
        this.selectedContent().type === 'dropdown' && <div>
          <ul className='no-bullet'>
            {
              this.selectedContent().content && this.selectedContent().content.map(link => {
                return <li><a alt={link.title} target={link.target || '_blank'} href={link.link}>{link.text}</a></li>
              })
            }
          </ul>
        </div>
      }
      {
        this.selectedContent().type === 'menu' && <div dangerouslySetInnerHTML={{ __html: sanitizeHtmlReact(this.selectedContent().content) }} />
      }
    </div>
  }

  renderTopItems () {
    return this.props.topNav && this.props.topNav.length ? this.props.topNav.map((item, index) => {
      return <li className='column small-12 item gutter-10 nav-item top-item' key={index}>
        <a href={item.link} target={item.target !== '_blank' ? '_self' : item.target}>
          <svg class='icon'><use xlinkHref={`/ClientResources/Website/images/svg/sprite.symbol.svg#icons--${item.icon}`} /></svg>
          <span>{item.label}</span>
        </a>
      </li>
    }) : null
  }

  render () {
    return <div>
      {
        this.props.alertActive && !this.state.menuOpen &&
        <div className={`pageAlert-container global`}>
          <div className='pageAlert-message-info'>
            <div className='row align-center align-middle'>
              <div className='column pageAlert-title'>
                <SVGInline className='pageAlert-icon' svg={exclamationCircle} /><div className='pageAlert-title-text'>{this.props.alertHeader}</div>
              </div>
              <div className='column pageAlert-message-preview'>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtmlReact(this.props.alertText, { allowedTags: ['strong', 'em', 'br'] }) }} />
                {this.props.alertCTALink && <a className='pageAlert-global-CTA' href={this.props.alertCTALink} target={this.props.alertLinkTarget || '_self'}>{this.props.alertCTAText}</a>}
              </div>
            </div>
          </div>
        </div>
      }
      <div className='row collapse row-full-width align-middle top-nav dynamic'>
        <div className='column small-12 main-nav--container'>
          <div className='row row-full-width align-justify align-middle'>
            {
              this.state.openMenu !== -1 && <div className={`show-for-small-only hide-for-medium-up main-nav--search main-nav--search-mobile`}>
                <button onClick={this.toggleContent} aria-label='Back' className='back-button'>
                  <div className='back-arrow arrow-bar' />
                  <span className='arrow-label'>Back to menu</span>
                </button>
              </div>
            }
            <div className='column main-nav--logo'>
              {
                (this.state.openMenu === -1 || window.screen.width > 767) &&
                <a href='/'>
                  <img className='hide-for-small-only' src={this.props.logo} alt='Logo' />
                  <img className='hide-for-medium' src={this.props.mobileLogo} alt='Logo' />
                </a>
              }
            </div>
            <div className='column shrink hide-for-medium text-center main-nav--menu-btn'>
              <button aria-label={this.state.menuOpen ? 'Close' : 'Menu'} onClick={this.toggleMenu}>
                <div className={`nav-icon ${this.state.menuOpen ? 'open' : ''}`}>
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            </div>
          </div>
        </div>
        <nav className='column small-12 medium-expand main-nav--nav-container'>
          <div className={`row collapse row-full-width main-nav--nav-content ${this.state.openMenu !== -1 ? 'slide' : ''} ${this.state.menuOpen ? 'shown' : ''}`}>
            <div className='column small-12 medium-expand small-order-2 medium-order-1 items-list'>
              <ul className='row collapse row-full-width no-bullet medium-text-center align-spaced main-nav--top-nav'>
                {this.renderTopItems()}
                {
                  (this.props.phone || this.props.email) &&
                  <li className='column small-12 item gutter-10 nav-item flow-right hide-for-small-only'>
                    {
                      this.props.phone && <a href={`tel:${cleanNumber(this.props.phone)}`} alt='Call Us'>
                        <svg class='icon phone'><use xlinkHref={`/ClientResources/Website/images/svg/sprite.symbol.svg#icons--phone_outline`} /></svg>
                        <span>{this.props.phone}</span>
                      </a>
                    }
                    {
                      this.props.email &&
                      <a href={`mailto:${this.props.email}`} alt='Email Us'>
                        <svg class='icon'><use xlinkHref={`/ClientResources/Website/images/svg/sprite.symbol.svg#icons--email`} /></svg>
                        <span>{this.props.email}</span>
                      </a>
                    }
                  </li>
                }
              </ul>
            </div>
            <div className='column small-12 small-order-3 medium-order-3 medium-order-1 items-list'>
              <ul className={`row row-full-width no-bullet medium-text-center collapse main-nav--nav main-nav--mobile ${this.props.mainNav && this.props.mainNav.length > 4 ? '' : 'short-main'}`}>
                {
                  this.renderMainItems()
                }
              </ul>
            </div>
            <div className='mega-menu-slide-container hide-for-medium'>
              {
                this.state.openMenu !== -1 && this.renderMenu()
              }
            </div>
          </div>
        </nav>
      </div>
    </div>
  }
}
