import React, { useState, useEffect } from 'react'
import './sticky-fad-button.scss'
import SVGInline from 'react-svg-inline'
import StethoscopeIcon from '../../../shared/assets/stethoscope-new-blue.svg'
import URLS from '../../../shared/constants/urls.js'

export default function FadStickyButton () {
  const [active, setActive] = useState()
  const screenMultiplier = 0.25

  useEffect(() => {
    window.addEventListener('scroll', displayBackToTop, {passive: true})

    return function cleanup () {
      window.removeEventListener('scroll', displayBackToTop)
    }
  }, [])

  const displayBackToTop = () => {
    // Clear our timeout throughout the scroll
    window.clearTimeout(this.isScrolling)
    // Set a timeout to run after scrolling ends
    this.isScrolling = setTimeout(() => {
      screenCheck(window.pageYOffset, window.screen.availHeight, screenMultiplier)
    }, 150)
  }

  const screenCheck = (scrollCheck, screenHeight, multiplier) => {
    var isActive = false
    isActive = scrollCheck > (screenHeight * multiplier)
    setActive(isActive)
  }

  return <a class='fad-floating-link' href={URLS.findADoctorUrl} style={{ opacity: active ? 1 : 0, visibility: active ? 'visible' : 'hidden' }}>
    <div class='title'>Find A Doc</div>
    <div class='icon-container'>
      <SVGInline className='stethoscope' svg={StethoscopeIcon} />
    </div>
    {active}
  </a>
}
