import React from 'react'
import ReactDOM from 'react-dom'
import HeaderNav from './HeaderNav/HeaderNav.jsx'
import ChatBot from '../Chatbot/ChatBot.jsx'
import PageAlert from '../shared/components/PageAlert/PageAlert.jsx'
import BackToTop from '../shared/components/BackToTop'
import StickyFADButton from '../shared/components/StickyFADButton'
import DynamicNav from './HeaderNav/DynamicNav.jsx'

window.React = React
window.ReactDOM = ReactDOM
window.HeaderNav = HeaderNav
window.DynamicNav = DynamicNav
window.ChatBot = ChatBot
window.PageAlert = PageAlert
window.BackToTop = BackToTop
window.StickyFADButton = StickyFADButton
