const buildDoctorUrl = (baseUrl, doctorData) => {
  if (doctorData && doctorData.name && doctorData.npi) {
    let doctorName = doctorData.name.replace(/[,.]/g, '').split(' ').join('-')
    return baseUrl + doctorData.npi + '/' + doctorName
  }
  return null
}

const buildProfilePageUrl = (baseUrl, name, id) => {
  if (name && id) {
    let doctorName = name.replace(/[,.]/g, '').split(' ').join('-')
    return baseUrl + id + '/' + doctorName
  }
  return null
}

export { buildProfilePageUrl }
export default buildDoctorUrl
