const SpecialtyIDs = [
  { id: '125', name: 'Advanced Heart Failure / Transplant Cardiology' },
  { id: '129', name: 'Allergy/Immunology' },
  { id: '132', name: 'Anesthesia Assistant' },
  { id: '133', name: 'Anesthesiology' },
  { id: '141', name: 'Audiology' },
  { id: '148', name: 'Cardiac Electrophysiology' },
  { id: '150', name: 'Cardiovascular Disease' },
  { id: '152', name: 'Certified Nurse Midwife' },
  { id: '153', name: 'Certified Registered Nurse Anesthetist' },
  { id: '157', name: 'Child Psychiatry' },
  { id: '171', name: 'Clinical Nurse Specialist' },
  { id: '206', name: 'Colon and Rectal Surgery' },
  { id: '211', name: 'Counselor' },
  { id: '215', name: 'Critical Care Medicine' },
  { id: '228', name: 'Dentistry' },
  { id: '229', name: 'Dermatology' },
  { id: '235', name: 'Dermatopathology' },
  { id: '244', name: 'Dietitian/Nutritionist' },
  { id: '247', name: 'Emergency Medicine' },
  { id: '253', name: 'Endocrinology/Metabolism' },
  { id: '256', name: 'Family Medicine' },
  { id: '257', name: 'Family Medicine Hospitalist' },
  { id: '264', name: 'Female Pelvic Medicine & Reconstructive Surgery' },
  { id: '265', name: 'Gastroenterology' },
  { id: '266', name: 'General Practice' },
  { id: '267', name: 'General Surgery' },
  { id: '269', name: 'Geriatric Medicine' },
  { id: '270', name: 'Gynecological Oncology' },
  { id: '271', name: 'Gynecology' },
  { id: '272', name: 'Hand Surgery' },
  { id: '275', name: 'Hematology/Oncology' },
  { id: '276', name: 'Hepatology' },
  { id: '280', name: 'Hospice & Palliative Medicine' },
  { id: '282', name: 'Hospital Physician' },
  { id: '284', name: 'Infectious Diseases' },
  { id: '289', name: 'Internal Medicine' },
  { id: '290', name: 'Internal Medicine Hospitalist' },
  { id: '314', name: 'Interventional Cardiology' },
  { id: '315', name: 'Interventional Radiology' },
  { id: '328', name: 'Maternal/Fetal Medicine' },
  { id: '337', name: 'Medical Toxicology' },
  { id: '341', name: 'Neonatology/Perinatology' },
  { id: '343', name: 'Nephrology' },
  { id: '344', name: 'Neurological Surgery' },
  { id: '345', name: 'Neurology' },
  { id: '347', name: 'Neuromusculoskeletal Medicine & Osteopathic Manipulative Med' },
  { id: '349', name: 'Neurophysiology' },
  { id: '351', name: 'Neuropsychology' },
  { id: '352', name: 'Neuroradiology' },
  { id: '353', name: 'Neurotology' },
  { id: '355', name: 'Nuclear Medicine' },
  { id: '361', name: 'Nurse Practitioner' },
  { id: '389', name: 'Obstetrics/Gynecology' },
  { id: '391', name: 'Occupational Medicine' },
  { id: '401', name: 'Ophthalmology' },
  { id: '412', name: 'Oral/Maxillofacial Surgery' },
  { id: '422', name: 'Osteopathic Manipulative Medicine' },
  { id: '426', name: 'Otolaryngology (ENT)' },
  { id: '435', name: 'Pain Management' },
  { id: '438', name: 'Pathology' },
  { id: '453', name: 'Pediatric Allergy' },
  { id: '456', name: 'Pediatric Cardiovascular Disease' },
  { id: '463', name: 'Pediatric Gastroenterology' },
  { id: '466', name: 'Pediatric Hospitalist' },
  { id: '471', name: 'Pediatric Neurology' },
  { id: '476', name: 'Pediatric Otolaryngology (ENT)' },
  { id: '482', name: 'Pediatric Radiology' },
  { id: '489', name: 'Pediatrics' },
  { id: '521', name: 'Physical Medicine/Rehabilitation' },
  { id: '534', name: 'Physician Assistant' },
  { id: '537', name: 'Plastic Surgery' },
  { id: '548', name: 'Podiatry' },
  { id: '557', name: 'Proctology' },
  { id: '559', name: 'Psychiatry' },
  { id: '593', name: 'Psychology' },
  { id: '595', name: 'Pulmonary Disease' },
  { id: '596', name: 'Radiation Oncology' },
  { id: '597', name: 'Radiology' },
  { id: '663', name: 'Reproductive Endocrinology/Infertility' },
  { id: '695', name: 'Rheumatology' },
  { id: '697', name: 'Sleep Disorders Medicine' },
  { id: '704', name: 'Sports Medicine' },
  { id: '715', name: 'Surgical Critical Care' },
  { id: '717', name: 'Thoracic Surgery' },
  { id: '719', name: 'Transfusion Medicine' },
  { id: '723', name: 'Urgent Care' },
  { id: '724', name: 'Urogynecology' },
  { id: '726', name: 'Urology' },
  { id: '727', name: 'Vascular Medicine' },
  { id: '728', name: 'Vascular Neurology' },
  { id: '729', name: 'Vascular Surgery' },
  { id: '759', name: 'Breast Surgery' },
  { id: '767', name: 'Chemical Pathology' },
  { id: '782', name: 'Critical Care Surgery' },
  { id: '784', name: 'Cytopathology' },
  { id: '819', name: 'Hematopathology' },
  { id: '876', name: 'Orthopedic Surgery' },
  { id: '935', name: 'Surgical Oncology' },
  { id: '951', name: 'Thoracic & Cardiac Surgery' },
  { id: '953', name: 'Genetic Counselor' },
  { id: '975', name: 'Epilepsy' },
  { id: '978', name: 'Complex General Surgical Oncology' },
  { id: '979', name: 'Oncology Medicine' }
]

export const getSpecialtyID = (name) => {
  let index = 0
  while (index < SpecialtyIDs.length) {
    if (SpecialtyIDs[index].name === name) {
      return SpecialtyIDs[index].id
    }
    index++
  }
  return name
}

export const getSpecialtyName = (id) => {
  let index = 0
  while (index < SpecialtyIDs.length) {
    if (SpecialtyIDs[index].id === id) {
      return SpecialtyIDs[index].name
    }
    index++
  }
  return id
}

export default SpecialtyIDs
