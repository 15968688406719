import Cookies from 'js-cookie'

export const replaceParam = (list, type, newValue) => {
  let found = list.filter(item => item.name === type)[0]
  let newObject = { name: type, value: String(newValue) }
  if (found) {
    if (newValue != null) {
      list[list.indexOf(found)] = newObject
    } else {
      list.splice(list.indexOf(found), 1)
    }
  } else {
    list.push(newObject)
  }
}

export const updateCookie = (params, cookieName) => {
  if (params && cookieName) {
    let existingCookie = Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : []
    for (var param in params) {
      if (params.hasOwnProperty(param)) {
        replaceParam(existingCookie, param, params[param])
      }
    }
    Cookies.set(cookieName, JSON.stringify(existingCookie), { expires: 1 })
  } else if (cookieName && !params) {
    Cookies.remove(cookieName)
  }
}
