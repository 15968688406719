export const getPhoneNumber = (address) => {
  if (address.cr && address.cr.length) {
    const providerPhone = address.cr.find(number => number.type.toLowerCase() === 'provider phone')
    return providerPhone || address.cr.find(number => number.type.toLowerCase() === 'phone')
  }
  return null
}

export const getFaxNumber = (address) => {
  if (address.cr && address.cr.length) {
    const providerFax = address.cr.find(number => number.type.toLowerCase() === 'provider fax')
    return providerFax || address.cr.find(number => number.type.toLowerCase() === 'fax')
  }
  return { Number: '' }
}

export const cleanNumber = (phoneNumber) => {
  if (!phoneNumber) return ''
  phoneNumber.replace('H', '4')
  phoneNumber.replace('E', '3')
  phoneNumber.replace('A', '2')
  phoneNumber.replace('L', '5')
  phoneNumber.replace('T', '8')
  return phoneNumber.replace(/[^0-9]/g, '')
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/[^\d\w]/g, '')
  var match = cleaned.match(/^([\d\w]{3})([\w\d]{3})([\w\d]{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  } else {
    match = cleaned.match(/^([\d\w]{1})([\d\w]{3})([\w\d]{3})([\w\d]{4})$/)
    if (match) {
      return '(' + match[2] + ')' + match[3] + '-' + match[4]
    }
  }
  return phoneNumberString
}
