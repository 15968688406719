import moment from 'moment'

export const getCurrentDate = () => moment().format('YYYY-MM-DD')
export const getDateMonthsFromCurrent = (numMonths) => moment().add(numMonths, 'months').format('YYYY-MM-DD')
export const getAgeInYears = (dateOfBirth) => moment().diff(dateOfBirth, 'years')
export const isDateDayAheadAndNotOnTheWeekend = (day) => { // open to suggestions for a better name
  const threeDaysFromNow = new Date(Date.now() + 1 * 86400000).getDay()
  let extraDays = 1
  if (threeDaysFromNow === 0) {
    extraDays = 2
  } else if (threeDaysFromNow === 6) {
    extraDays = 3
  }
  return day.getTime() === new Date(Date.now() + extraDays * 86400000).setHours(12, 0, 0, 0)
}
