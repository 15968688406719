const gatewayPage = '/schedule-online'

const URLS = {
  appointmentSelectionOpenUrl: '/appointment-selection',
  guestSchedulingUrl: '/guest-scheduling',
  telehealthAppointmentSelectionUrl: '/telehealth-appointments',
  patientInfoUrl: '/patientinfo',
  reviewUrl: '/review',
  confirmationUrl: '/confirmation',
  verifyURL: '/verify',
  chooseProviderURL: '/choose',
  gatewayPageUrl: gatewayPage,
  sadRootUrl: '/find-a-doctor/results?type=doctors',
  mammSelectionPageUrl: `${gatewayPage}/mammogram`,
  mammSchedulingUrl: `${gatewayPage}/mammogram-scheduling`,
  findADoctorUrl: '/find-a-doctor',
  profileUrl: '/profile',
  urgentCareGateway: '/services/urgent-care/urgent-care-video-visits/',
  urgentCareLocations: '/locations/urgent-care',
  defaultLocationImage: '/ClientResources/Website/images/location-placeholder.png',
  defaultPhotoUrl: window.defaultPhotoUrl ? window.defaultPhotoUrl : '/ClientResources/Website/images/FAD-no-image.png',
  appleAddressUrl: window.appleAddressUrl ? window.appleAddressUrl : 'https://itunes.apple.com/us/app/ohiohealth/id1064224526?mt=8',
  androidAddressUrl: window.androidAddressUrl ? window.androidAddressUrl : 'https://play.google.com/store/apps/details?id=com.ohiohealth.mychart',
  mychartApptUrl: process.env.NODE_ENV === 'production' ? window.mychartApptUrl ? window.mychartApptUrl : 'https://mychart.ohiohealth.com/mychart/default.asp?postloginmode=scheduling' : 'https://mycharttst.ohiohealth.com/mycharttst/default.asp?postloginmode=scheduling',
  mychartUrl: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('test') > -1 || window.location.href.indexOf('dev.ohiohealth') > -1 || window.location.href.indexOf('stage.ohiohealth') > -1 ? 'https://mychartst.ohiohealth.com/mychartTST' : 'https://mychart.ohiohealth.com/mychart',
  testMyChartUrl: 'https://mychartst.ohiohealth.com/mychartTST',
  prodMychartUrl: 'https://mychart.ohiohealth.com/mychart',
  chooseVisitTypeUrl: '/choose-visit-type/:id',
  rootUrl: window.location.hostname && window.location.hostname.indexOf('test-12') > -1 ? 'test-12.ohiohealth.com' : window.location.hostname.indexOf('test') > -1 ? 'test.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('dev') > -1 ? 'dev.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('stage') > -1 ? 'stage.ohiohealth.com' : 'ohiohealth.com'))
}

export default URLS
