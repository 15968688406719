import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const generatePdf = (pdfData) => {
  const headers = ["Physician Name \nOffice Phone Number", "Practice Name \nPrimary Location", "Specialties"];
  const data = pdfData.map((x) => {
    return [
      `${x.title}\n${x.phone || "Unavailable"}`,
      `${Object.values(x.practices)[0] || "Unavailable"}\n${
        x.address ? `${x.address.adr} ${x.address.c}, ${x.address.s} ${x.address.z}` : "Unavailable"
      }`,
      x.specialties.join(", "),
    ];
  });
  const timeStamp = new Date().toLocaleString();
  const doc = new jsPDF();

  const generateTimeStamp = () => {
    doc.setFontSize(5);
    doc.text(`${timeStamp}`, 10, doc.internal.pageSize.height - 10);
  };

  const generateHeading = () => {
    doc.setFontSize(16);
    doc.text("OhioHealth Clinically Integrated Network Physician Search", doc.internal.pageSize.width / 2, 20, { align: "center" });
  };
  generateHeading()
  autoTable(doc, {
    head: [headers],
    body: data,
    startY: 30,
    theme: "grid",
    styles: {
      fontSize: 7,
    },
    columnStyles: {
      0: {
        cellWidth: 40,
      },
      2: {
        cellWidth: 40,
      },
    },
    headStyles: {
      halign: "center",
      valign: "middle",
    },
    showHead:'everyPage',
    showFoot:'everyPage',
    didDrawPage: () => {
      generateTimeStamp();
    },
  });

  const pageCount = doc.internal.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(5);
    doc.text(`Page ${String(i)} of ${String(pageCount)}`, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, null, null, "right");
  }

  doc.autoPrint();
  doc.save(`Physician Search-${timeStamp}.pdf`);
};
