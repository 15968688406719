// Google Analytics 4 - DataLayer Pushes //

export const scheduleButtonEvent = (data) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'event': 'schedule_button',
      'schedule_flow': data.flow,
      'provider_name': data.providerName,
      'provider_id': data.providerID,
      'provider_npi': data.providerNPI,
      'provider_specialty': data.providerSpecialty,
      'facility_name': data.facilityName,
      'facility_address': data.facilityAddress
    })
  }
}

export const scheduleQuestionEvent = (data) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'event': 'schedule_question',
      'schedule_flow': data.flow,
      'schedule_question_number': data.scheduleQuestionNumber,
      'schedule_question': data.scheduleQuestion,
      'schedule_question_answer': data.scheduleQuestionAnswer
    })
  }
}

export const scheduleAppointmentEvent = (data) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'event': 'schedule_appointment',
      'schedule_flow': data.flow === 'open' ? 'fad' : data.flow,
      'provider_name': data.providerName,
      'provider_id': data.providerID,
      'provider_npi': data.providerNPI,
      'provider_specialty': data.providerSpecialty,
      'provider_address': data.providerAddress,
      'facility_name': data.facilityName,
      'facility_address': data.facilityAddress,
      'schedule_step_number': data.scheduleStepNumber,
      'schedule_step_name': data.scheduleStepName
    })
  }
}

export const appointmentConfirmationEvent = (data) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'event': 'appointment_confirmation',
      'schedule_flow': data.flow === 'open' ? 'fad' : data.flow,
      'provider_name': data.providerName,
      'provider_id': data.providerID,
      'provider_npi': data.providerNPI,
      'provider_specialty': data.providerSpecialty,
      'provider_address': data.providerAddress,
      'facility_name': data.facilityName,
      'facility_address': data.facilityAddress
    })
  }
}

export const clickToCallEvent = (data) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'event': 'click_to_call',
      'schedule_flow': data.flow === 'open' ? 'fad' : data.flow,
      'provider_phone_number': data.providerPhoneNumber,
      'provider_name': data.providerName,
      'provider_id': data.providerID,
      'provider_npi': data.providerNPI,
      'provider_specialty': data.providerSpecialty,
      'provider_address': data.providerAddress,
      'facility_name': data.facilityName,
      'facility_address': data.facilityAddress
    })
  }
}
// ==================================== //

export const addCarouselEvent = (label) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'category': 'OPG',
      'action': 'Engagement',
      'label': label
    })
  }
}

export const addProfileShareEvent = () => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'category': 'Doctor Profile',
      'action': 'Share',
      'label': 'Print'
    })
  }
}

export const addQuizCompleteEvent = (label) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'category': 'Health and Wellness',
      'action': 'Quiz Complete',
      'label': label
    })
  }
}

export const GeolocationAcceptOrDeny = (accpet) => {
  window.localStorage.setItem('geoPermission', accpet)
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'category': 'Geolocation Prompt',
      'action': 'click',
      'label': accpet === 'accept' ? 'Accepted' : 'Declined'
    })
  }
}

export const OBGYNSchedulingFinishedEvent = (reasons) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'WRH Scheduling Complete': reasons
    })
  }
}

export const OBGYNSchedulingStartedEvent = () => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'WRH Scheduling Started': true
    })
  }
}

export const OBGYNSchedulingFailedEvent = (reasons) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'WRH Scheduling Unable To Schedule': reasons
    })
  }
}

export const OpenSchedulingFailedToSchedule = (error) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'Open Scheduling Unable To Schedule': error
    })
  }
}

export const UCReasonForVisit = (reason) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'UC Reason For Visit': reason
    })
  }
}

export const UCReasonForVisitConditions = (reasons) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'UC Reason For Visit Conditions': reasons
    })
  }
}

export const UCReasonForVisitDisclaimer = (disclaimer) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'Disclaimer': disclaimer,
      'Answer': 'OK'
    })
  }
}

export const UCReasonForVisitQuestion = (question, answer) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'Question': question,
      'Answer': answer
    })
  }
}

export const UCReasonForVisitInPerson = () => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'Visit In Person Urgent Care': true
    })
  }
}

export const UCReasonForVisitUnresolvedProblem = (answer) => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'Unresolved Problem': answer
    })
  }
}

export const UCLocationSelectedWhenEligibleForVideoVisit = () => {
  const dataLayer = window.dataLayer
  if (dataLayer) {
    dataLayer.push({
      'category': 'Urgent Care Guest Scheduling',
      'action': 'Click',
      'label': 'Selected location when eligible for Video Visit.'
    })
  }
}
