
import React from 'react'
import sanitizeHtmlReact from 'sanitize-html-react'
import SVGInline from 'react-svg-inline'
import searchIcon from '../../shared/assets/MagnifyingGlass.svg'
import dropdownArrow from '../../shared/assets/DropdownArrow.svg'
import exclamationCircle from '../../shared/assets/exclamation-circle.svg'
import { togglePreventScroll } from '../../shared/utility-functions'
import './header-nav.scss'

export default class HeaderNav extends React.Component {
  constructor (props) {
    super(props)
    props.healthWellnessPage.text = sanitizeHtmlReact(props.healthWellnessPage.text)
    props.patientsVisitorsPage.text = sanitizeHtmlReact(props.patientsVisitorsPage.text)
    this.patientMenu = sanitizeHtmlReact(props.patientsVisitorsMegaMenu, { allowedTags: ['p', 'br', 'ul', 'li', 'div', 'a'] })
    this.healthMenu = sanitizeHtmlReact(props.healthWellnessNavMegaMenu, { allowedTags: ['p', 'br', 'ul', 'li', 'div', 'a'] })
    this.siteWrapper = document.querySelector('.site-wrapper')
    this.state = {
      mobileSearchOpen: false,
      menuOpen: false,
      patientMenuOpen: false,
      healthMenuOpen: false,
      search: '',
      scrollPosition: 0,
      alertClass: this.props.alertClass || 'global',
      alertIsDismissed: window.sessionStorage.getItem('alertIsDismissed') === 'true'
    }
    this.offClick = this.offClick.bind(this)
    this.searchBtnClick = this.searchBtnClick.bind(this)
    this.toggleMobileSearch = this.toggleMobileSearch.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleMegaMenu = this.toggleMegaMenu.bind(this)
    this.pageAlertDismissOnClick = this.pageAlertDismissOnClick.bind(this)
    window.addEventListener('click', this.offClick, {passive: true})
  }

  toggleMenu () {
    let oldScrollPosition = window.pageYOffset
    let newState = !this.state.menuOpen
    this.setState({ menuOpen: newState, mobileSearchOpen: false })
    setTimeout(() => {
      togglePreventScroll(newState)
    }, 100)
    setTimeout(() => {
      this.setState({ patientMenuOpen: false, healthMenuOpen: false })
    }, 300)
    if (newState) {
      this.setState({ scrollPosition: oldScrollPosition })
      window.scroll(0, 0)
    } else {
      setTimeout(() => {
        window.scroll(0, this.state.scrollPosition)
      }, 200)
    }
  }

  offClick (e) {
    if ((this.state.patientMenuOpen || this.state.healthMenuOpen) && window.screen.width > 767) {
      try {
        let notMenuButton = e.target.parentElement.className.indexOf('item with-arrow main-nav--mega-menu-actuator') === -1
        let notMenuOption = e.target.parentElement.className.indexOf('medium-expand') === -1
        if (notMenuButton && notMenuOption && (this.state.patientMenuOpen || this.state.healthMenuOpen)) {
          this.setState({ patientMenuOpen: false, healthMenuOpen: false })
        }
      } catch (error) { }
    }
  }

  searchBtnClick (e) {
    e.preventDefault()
    if (e.srcElement && e.srcElement.children && e.srcElement.children.length && e.srcElement.children[0].value) {
      this.state.search = encodeURIComponent(e.srcElement.children[0].value)
      window.location.href = '/search?q=' + this.state.search + '&type=site&f=site'
    }
  }

  toggleMegaMenu (key) {
    let newState
    if (typeof key === 'object') {
      newState = { patientMenuOpen: false, healthMenuOpen: false }
    } else if (key === 'patientMenu') {
      newState = { patientMenuOpen: !this.state.patientMenuOpen, healthMenuOpen: false }
    } else {
      newState = { patientMenuOpen: false, healthMenuOpen: !this.state.healthMenuOpen }
    }
    this.setState(newState)
  }

  toggleMobileSearch () {
    this.setState(prevState => {
      return { mobileSearchOpen: !prevState.mobileSearchOpen }
    })
  }

  renderItems () {
    return this.props.eyeBrowNavItems.map((item, index) => {
      return <li className='column small-12 item gutter-10 nav-item' key={index}>
        <a href={item.Link} target={item.Target !== '_blank' ? '_self' : item.Target}>
          <svg class='icon'><use xlinkHref={`/ClientResources/Website/images/svg/sprite.symbol.svg#icons--${item.Icon}`} /></svg>
          <span>{item.Label}</span>
        </a>
      </li>
    })
  }

  pageAlertDismissOnClick () {
    if (!this.state.alertIsDismissed) {
      this.setState({ alertIsDismissed: true })
      window.sessionStorage.setItem('alertIsDismissed', true)
    }
  }

  render () {
    return <div>
      {
        this.props.alertActive && (!this.state.mobileSearchOpen && !this.state.menuOpen) &&
        <div className={`pageAlert-container ${this.state.alertClass} ${this.state.alertIsDismissed === true ? `dismissed` : ``}`}>
          <div className='pageAlert-message-info'>
            <div className='row align-center align-middle'>
              <div className='column pageAlert-title'>
                <SVGInline className='pageAlert-icon' svg={exclamationCircle} /><div className='pageAlert-title-text'>{this.props.alertHeader}</div>
              </div>
              <div className='column pageAlert-message-preview'>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtmlReact(this.props.alertText, { allowedTags: ['strong', 'em', 'br'] }) }} />
                {this.props.alertCTALink && <a className='pageAlert-global-CTA' href={this.props.alertCTALink} target={this.props.alertLinkTarget || '_self'}>{this.props.alertCTAText}</a>}
              </div>
            </div>
          </div>
          <div className='pageAlert-dismiss' onClick={this.pageAlertDismissOnClick} />
        </div>
      }
      <div className='row collapse row-full-width align-middle top-nav'>
        <div className='column small-12 main-nav--container'>
          <div className='row row-full-width align-justify align-middle'>
            <div className={`show-for-small-only hide-for-medium-up main-nav--search main-nav--search-mobile`}>
              {
                !this.state.menuOpen &&
                <button onClick={this.toggleMobileSearch} aria-label='Search' className='main-nav--search--actuator'>
                  <div className={`search-icon ${this.state.mobileSearchOpen ? 'open' : ''}`} />
                </button>
              }
              {
                (this.state.patientMenuOpen || this.state.healthMenuOpen) &&
                <button onClick={this.toggleMegaMenu} aria-label='Back' className='back-button'>
                  <div className='back-arrow arrow-bar' />
                  <span className='arrow-label'>Back to menu</span>
                </button>
              }
            </div>
            <div className='column main-nav--logo'>
              {
                ((!this.state.healthMenuOpen && !this.state.patientMenuOpen) || window.screen.width > 767) &&
                <a href='/'>
                  <img className='hide-for-small-only' src={this.props.logoImage} alt='OhioHealth Logo' />
                  <img className='hide-for-medium' src={this.props.mobileImage} alt='OhioHealth Logo' />
                </a>
              }
            </div>
            <div className='column shrink hide-for-medium text-center main-nav--menu-btn'>
              <button aria-label={this.state.menuOpen ? 'Close' : 'Menu'} onClick={this.toggleMenu}>
                <div className={`nav-icon ${this.state.menuOpen ? 'open' : ''}`}>
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            </div>
          </div>
        </div>
        <nav className='column small-12 medium-expand main-nav--nav-container'>
          <div className={`row collapse row-full-width main-nav--nav-content ${this.state.patientMenuOpen || this.state.healthMenuOpen ? 'slide' : ''} ${this.state.menuOpen ? 'shown' : (this.state.mobileSearchOpen ? 'search' : '')}`}>
            <div className={`show-for-small-only hide-for-medium-up mobile-search open`}>
              <form onSubmit={this.searchBtnClick} className={`search-panel ${this.state.mobileSearchOpen || this.state.menuOpen ? 'open' : ''}`}>
                <input type='text' placeholder='Search' aria-label='Search terms' maxLength='100' />
                <button className={`${this.state.mobileSearchOpen || this.state.menuOpen ? 'open' : 'closed'}`} aria-label='Search' type='submit'>
                  <SVGInline svg={searchIcon} className='icon' />
                </button>
              </form>
            </div>
            {
              this.props.eyeBrowNavItems !== null && this.props.eyeBrowNavItems.length > 0 &&
              <div className='column small-12 medium-expand small-order-2 medium-order-1 items-list'>
                <ul className='row collapse row-full-width no-bullet medium-text-center align-spaced main-nav--top-nav'>
                  {this.renderItems()}
                </ul>
              </div>
            }

            <form onSubmit={this.searchBtnClick} className={`column small-12 medium-3 small-order-1 medium-order-3 main-nav--search hide-for-small-only`} id='main-nav-search-form'>
              <input type='text' className='main-nav--search-input' placeholder='Search' aria-label='Search terms' />
              <button className='main-nav--search-btn' type='submit' aria-label='Search'>
                <SVGInline svg={searchIcon} className='icon' />
              </button>
            </form>
            <div className='column small-12 small-order-3 medium-order-3 medium-order-1 items-list'>
              <ul className='row row-full-width no-bullet medium-text-center collapse main-nav--nav main-nav--mobile'>
                <li className='column small-12 medium-expand'><a className={`item ${(this.props.findADocPage !== null && this.props.sectionID === this.props.findADocPage.ID) && !(this.state.healthMenuOpen || this.state.patientMenuOpen) ? 'selected' : ''}`} href={this.props.findADocPage.page}>{this.props.findADocPage.text}</a></li>
                <li className='column small-12 medium-expand'><a className={`item ${(this.props.locationPage !== null && this.props.sectionID === this.props.locationPage.ID) && !(this.state.healthMenuOpen || this.state.patientMenuOpen) ? 'selected' : ''}`} href={this.props.locationPage.page}>{this.props.locationPage.text}</a></li>
                <li className='column small-12 medium-expand'><a className={`item ${(this.props.servicesPage !== null && this.props.sectionID === this.props.servicesPage.ID) && !(this.state.healthMenuOpen || this.state.patientMenuOpen) ? 'selected' : ''}`} href={this.props.servicesPage.page}>{this.props.servicesPage.text}</a></li>
                <li className='column small-12 medium-expand'>
                  <button onClick={() => { this.toggleMegaMenu('patientMenu') }} className={`item with-arrow main-nav--mega-menu-actuator ${this.state.patientMenuOpen ? 'active' : ''} ${(this.props.patientsVisitorsPage !== null && this.props.sectionID === this.props.patientsVisitorsPage.ID) ? 'selected' : ''}`}>
                    <span>{this.props.patientsVisitorsPage.text}</span>
                    <SVGInline svg={dropdownArrow} className='icon hide-for-medium' />
                  </button>
                  <div className='main-nav--mega-menu-container text-left' style={{ height: this.state.patientMenuOpen ? 'auto' : 0 }}>
                    <div className='row row-full-width align-right patient-menu main-nav--mega-menu-content'>
                      <div className='column small-12 medium-11'>
                        <div className='row row-full-width align-spaced' dangerouslySetInnerHTML={{ __html: this.patientMenu }} />
                      </div>
                    </div>
                  </div>
                </li>
                <li className='column small-12 medium-expand'>
                  <button onClick={() => { this.toggleMegaMenu('healthMenu') }} className={`item with-arrow main-nav--mega-menu-actuator ${this.state.healthMenuOpen ? 'active' : ''} ${(this.props.healthWellnessPage !== null && this.props.sectionID === this.props.healthWellnessPage.ID) ? 'selected' : ''}`}>
                    <span>{this.props.healthWellnessPage.text}</span>
                    <SVGInline svg={dropdownArrow} className='icon hide-for-medium' />
                  </button>
                  <div className='main-nav--mega-menu-container health-menu text-left' style={{ height: this.state.healthMenuOpen ? 'auto' : 0 }}>
                    <div className='row row-full-width align-right main-nav--mega-menu-content'>
                      <div className='column small-12 medium-12 large-11'>
                        <div className='row row-full-width align-spaced' dangerouslySetInnerHTML={{ __html: this.healthMenu }} />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className='mega-menu-slide-container hide-for-medium'>
              {
                this.state.patientMenuOpen && <div>
                  <div className='mega-header'>{this.props.patientsVisitorsPage.text}</div>
                  <div dangerouslySetInnerHTML={{ __html: this.patientMenu }} />
                </div>
              }
              {
                this.state.healthMenuOpen && <div>
                  <div className='mega-header'>{this.props.healthWellnessPage.text}</div>
                  <div dangerouslySetInnerHTML={{ __html: this.healthMenu }} />
                </div>
              }
            </div>
          </div>
        </nav>
      </div>
    </div>
  }
}
