const GOOGLE_DIRECTIONS_URL = 'https://www.google.com/maps/dir/?api=1&destination='

const directionsUrlBuilder = address => {
  if (!address) return null
  let addressArray = [address.street, address.cityStateZip]
  let addressString = encodeURI(addressArray.join('+'))
  return GOOGLE_DIRECTIONS_URL + addressString
}

export default directionsUrlBuilder
