export const acronyms = [
  'MD',
  'OhioHealth'
]

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      if (!acronyms.includes(txt)) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      } else {
        return txt
      }
    }
  )
}

export const titleCaseStringArray = (stringArray) => {
  let newArray = []
  if (stringArray) {
    for (let i = 0; i < stringArray.length; i++) {
      let str = stringArray[i]
      str = toTitleCase(str)
      newArray.push(str)
    }
    return newArray
  } else {
    return stringArray
  }
}

export default titleCaseStringArray
