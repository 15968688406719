export const googleHelper = (lat, lng, key, width = 300, height = 150) => {
  return `https://maps.googleapis.com/maps/api/staticmap?size=${width}x${height}&maptype=roadmap&markers=color:blue|label:A|${lat},${lng}&key=${key}`
}

export const addressBuilder = (address) => {
  return {
    street: address.adr,
    cityStateZip: `${address.c}, ${address.s} ${address.z}`
  }
}
