import isEmptyObject from './isEmptyObject.js'
import scrollToLocation from './scrollToLocation.js'
import { googleHelper, addressBuilder } from './google-map-helper'
import buildDoctorUrl, { buildProfilePageUrl } from './BuildDoctorUrl'
import { getCurrentDate, getDateMonthsFromCurrent, getAgeInYears, isDateDayAheadAndNotOnTheWeekend } from './dates'
import directionsUrlBuilder from './directionsUrlBuilder'
import { getPhoneNumber, getFaxNumber, cleanNumber, formatPhoneNumber } from './phoneHelpers'
import emailUrl from './shareUrlBuilder'
import { getURLParameters, insertFiltersIntoURLParameters, insertEmptyFilters, getLookingFor, shouldScrollToRatingsAndReviews, getParameterByName } from './searchParams'
import decodeHtmlEntities from './decodeHtmlEntities'
import { updateCookie } from './paramCookie'
import { togglePreventScroll } from './ohcom-utils'
import launchVideo from './launchVideoModal.js'
import isCardio from './isCardio.js'
import titleCaseStringArray from './toTitleCase'
import { GeolocationAcceptOrDeny } from './googleTagManager'
import {generatePdf} from './generate-pdf.js'

export { isEmptyObject, scrollToLocation, googleHelper, addressBuilder, emailUrl, isCardio,
  buildDoctorUrl, buildProfilePageUrl, getCurrentDate, getDateMonthsFromCurrent, getAgeInYears, isDateDayAheadAndNotOnTheWeekend, directionsUrlBuilder, decodeHtmlEntities,
  getPhoneNumber, getFaxNumber, cleanNumber, formatPhoneNumber, getURLParameters, insertFiltersIntoURLParameters, insertEmptyFilters, getParameterByName, getLookingFor, shouldScrollToRatingsAndReviews, updateCookie, togglePreventScroll, launchVideo, titleCaseStringArray,
  GeolocationAcceptOrDeny, generatePdf }
