import React from 'react'
import sanitizeHtmlReact from 'sanitize-html-react'
import SVGInline from 'react-svg-inline'
import Modal from '../Modal'
import exclaimation from '../../assets/exclamation-circle.svg'
import './page-alert.scss'

export default class PageAlert extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      displayModal: false,
      type: this.props.type || 'page',
      secondaryAlertIsDismissed: window.sessionStorage.getItem('secondaryAlertIsDismissed') === 'true'
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.pageAlertDismissOnClick = this.pageAlertDismissOnClick.bind(this)
  }

  toggleModal () {
    this.setState(prevState => {
      return { displayModal: !prevState.displayModal }
    })
  }

  hideModal () {
    this.setState({ displayModal: false })
  }

  pageAlertDismissOnClick () {
    if (!this.state.secondaryAlertIsDismissed) {
      this.setState({ secondaryAlertIsDismissed: true })
      window.sessionStorage.setItem('secondaryAlertIsDismissed', true)
    }
  }

  render () {
    return (
      <div className={`pageAlert-container ${this.state.type} ${this.state.secondaryAlertIsDismissed === true ? `dismissed` : ``}`}>
        {this.props.title && <div className='pageAlert-message-info'>
          <div className='row align-center align-middle'>
            <div className='column pageAlert-title'><SVGInline className='pageAlert-icon' svg={exclaimation} /><div className='pageAlert-title-text'>{this.props.title}</div></div>
            <div className='column pageAlert-message-preview'>
              <div dangerouslySetInnerHTML={{__html: sanitizeHtmlReact(this.props.preview, { allowedTags: ['i', 'strong', 'em', 'br', 'b', 'a'] })}} />
              {this.props.content && <div className='pageAlert-CTA' onClick={this.toggleModal}>LEARN MORE</div>}
            </div>
          </div>
        </div>}
        <Modal hideFunction={this.hideModal} showModal={this.state.displayModal}>
          <div className='modal-wrapper'>
            <SVGInline className='icon' svg={exclaimation} />
            <div className='modal-title'>{this.props.title}</div>
            <div className='modal-text' dangerouslySetInnerHTML={{__html: sanitizeHtmlReact(this.props.content, { allowedTags: ['i'] })}} />
          </div>
        </Modal>
        <div className='pageAlert-dismiss' onClick={this.pageAlertDismissOnClick} />
      </div>
    )
  }
}
