import React from 'react'
import SVGInline from 'react-svg-inline'
import ohLogo from '../shared/assets/chatbot.svg'
import closeIcon from '../shared/assets/x.svg'
import {togglePreventScroll} from '../shared/utility-functions'
import './ChatBot.scss'

export default class ChatBot extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle () {
    let newState = !this.state.open
    togglePreventScroll(newState)
    let iframeSrc = document.getElementById('bot-container').src
    if (newState && iframeSrc !== this.props.iframeURL) {
      document.getElementById('bot-container').src = this.props.iframeURL
    } else if (!newState) {
      document.getElementById('bot-container').src = 'about:blank'
    }
    this.setState({
      open: newState
    })
  }

  render () {
    return <div className={this.state.open ? 'chat-button-container' : 'chat-button-container closed'}>
      <div className={this.state.open ? 'toggle-button' : 'toggle-button toggle-closed'} onClick={this.handleToggle}>
        <SVGInline svg={closeIcon} className='close' />
        <div className='logo-image' >
          <SVGInline svg={ohLogo} />
        </div>
      </div>
      <div className={this.state.open ? 'chat-bot-iframe-container' : 'chat-bot-iframe-container closed'}>
        <div className={this.state.open ? 'loading-status bot-iframe' : 'closed-iframe'}>
          <iframe title='Health Chat Bot' id='bot-container' className='bot-iframe' src='about:blank' frameBorder='0' />
        </div>
      </div>
    </div>
  }
}
